import React from 'react';
import classnames from 'classnames';
import './steps.less';
import { KeyAndLabel } from '../../../appTypes';
import { ShTranslatable } from '@shoootin/translations';
import { OrderStepLabels } from '../orderPageTranslations';
import { OrderStep } from '../context/orderPageContextSteps';

export type StepItem = KeyAndLabel<OrderStep> & {
  modifier: 'next' | 'previous' | 'current';
  clickable: boolean;
};

const Steps = ({
  stepItems,
  onStepClick,
}: {
  stepItems: StepItem[];
  onStepClick: (step: StepItem) => void;
}) => (
  <div className="container">
    <ol className="form-steps">
      {stepItems.map(step => (
        <li
          key={step.key}
          className={classnames('form-steps__step', step.modifier)}
          onClick={step.clickable ? () => onStepClick(step) : undefined}
          css={{ cursor: step.clickable ? 'pointer' : undefined }}
        >
          <ShTranslatable id={OrderStepLabels[step.key].id}>
            <span>{step.label}</span>
          </ShTranslatable>
        </li>
      ))}
    </ol>
  </div>
);

export default Steps;
