import React from 'react';

const ChevronPrevSvg = (props: any) => (
  <svg
    className="chevron-prev"
    viewBox="0 0 28 28"
    height={28}
    width={28}
    fill="none"
    {...props}
  >
    <polyline points="20,26.25 7.75,14 20,1.75 " />
  </svg>
);

export default ChevronPrevSvg;
