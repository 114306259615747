import React from 'react';

import { Spring, animated, config } from 'react-spring';
import OrderPageStepsLayout from './orderPageStepsLayout';
import Steps from './components/steps';
import { useOrderPageContext } from 'page/order/context/orderPageContext';
import { isDev } from 'appEnv';

const OrderPageContent = () => {
  const { steps } = useOrderPageContext();

  return (
    <OrderPageStepsLayout
      steps={
        <Steps
          stepItems={steps.stepItems}
          onStepClick={steps.handleStepItemClick}
        />
      }
    >
      <Spring
        key={steps.currentStep}
        config={config.slow}
        delay={200}
        from={{
          opacity: 0,
        }}
        to={{ opacity: 1 }}
      >
        {props => {
          const StepComponent = steps.stepComponent;
          const devControls = isDev && (
            <div
              css={{
                margin: 10,
                padding: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {steps.hasPrevious && (
                <div
                  onClick={steps.previous}
                  css={{
                    margin: 10,
                    padding: 10,
                    border: 'solid',
                    cursor: 'pointer',
                  }}
                >
                  {'<'} Previous
                </div>
              )}
              <div css={{ flex: 1 }} />
              {steps.hasNext && (
                <div
                  onClick={steps.next}
                  css={{
                    margin: 10,
                    padding: 10,
                    border: 'solid',
                    cursor: 'pointer',
                  }}
                >
                  Next {'>'}
                </div>
              )}
            </div>
          );
          return (
            <animated.div style={props}>
              <StepComponent />
              {false && devControls}
            </animated.div>
          );
        }}
      </Spring>
    </OrderPageStepsLayout>
  );
};

export default OrderPageContent;
