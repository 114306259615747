import { ReactNode } from 'react';
import { MainLayout } from '../../layout/mainLayout';
import classnames from 'classnames';
import React from 'react';

const OrderPageMainLayout = ({
  className,
  children,
  showHeader = true,
  showFooter = true,
}: {
  className?: string;
  children: ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
}) => (
  <MainLayout
    className={classnames('page-form page-order light', className)}
    showContact={false}
    showHeader={showHeader}
    showFooter={showFooter}
    pageName="order"
  >
    {children}
  </MainLayout>
);

export default OrderPageMainLayout;
