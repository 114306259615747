import React, { ReactNode } from 'react';
import { useHeaderSize } from '../../layout/mainLayout';
import useScrollTopThresholdReached from '../../hooks/useScrollTopThreshold';
import classnames from 'classnames';

const OrderPageStepsLayout = ({
  steps,
  children,
}: {
  steps: ReactNode;
  children: ReactNode;
}) => {
  // The breadcrumb is fixed if we scroll past the height of the header
  // Note the header has a different size depending on viewport size
  const headerSize = useHeaderSize();
  const isBreadcrumbFixed = useScrollTopThresholdReached(headerSize.height);
  return (
    <>
      <div
        className={classnames('form-breadcrumbs', { fixed: isBreadcrumbFixed })}
      >
        {steps}
      </div>
      <div className="form-content">{children}</div>
    </>
  );
};

export default OrderPageStepsLayout;
