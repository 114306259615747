import React from 'react';
import OrderPageMainLayout from '../orderPageMainLayout';
import { PageErrorContent } from '../../pageError';

const OrderLayoutError = ({
  message,
  isCheckout,
}: {
  message?: string;
  isCheckout?: boolean;
}) => (
  <OrderPageMainLayout showFooter={!isCheckout} showHeader={!isCheckout}>
    <PageErrorContent message={message} />
  </OrderPageMainLayout>
);
export default OrderLayoutError;
