import './styles/orderPageStyle';
import './styles/orderPageCheckoutLayout.less';

import React, { useEffect, ReactNode } from 'react';
import { OrderPageContextProvider } from 'page/order/context/orderPageContext';
import OrderPageContent from './orderPageContent';
import { useQueryString } from '../../layout/appContext';
import { useAsync } from 'react-async-hook';
import OrderPageMainLayout from './orderPageMainLayout';
import { getCurrentUserOption } from '../../appAPI';
import { isServer } from '../../utils/gatsbyUtils';
import OrderLayoutSpinner from './components/orderLayoutSpinner';
import OrderLayoutError from './components/orderLayoutError';
import { CheckoutCurrentUserProvider } from '../../state/currentUserState';
import { pick, mapValues } from 'lodash';
import {
  OrderPageState,
  useInitialOrderPageState,
} from './context/orderPageContextState';
import CloseSvg from '../../components/svg/picto/closeSvg';
import useBodyClass from '../../hooks/useBodyClass';
import useHtmlClass from '../../hooks/useHtmlClass';
import LogoSvg from '../../components/svg/logoSvg';

import { ShCountry } from '@shoootin/config';
import { ShApiClient } from '@shoootin/api';
import { SimpleQueryStringObject } from '@shoootin/utils';

const AllowedCheckoutHeaders = [
  'Authorization', // Authorization Token
  'Checkout-Token', // Checkout Token
  'Checkout-Partner', // Partner Id
  // Add other headers here
];

const getHeaders = (
  queryString: SimpleQueryStringObject,
): SimpleQueryStringObject => {
  console.log('useQueryString()', queryString);
  console.log('AllowedCheckoutHeaders', AllowedCheckoutHeaders);
  const headers = pick(queryString, AllowedCheckoutHeaders);
  const decodedHeaders = mapValues(headers, function(header) {
    return decodeURIComponent(header!);
  });
  console.log('headers', decodedHeaders);
  return decodedHeaders;
};

const getParterName = (queryString: SimpleQueryStringObject): string => {
  return decodeURIComponent(queryString['Checkout-Partner-Name']!);
};

const OrderPageCheckout = () => {
  if (isServer()) {
    return <OrderLayoutSpinner />;
  }

  //We need to know when page is checkout to add class on html and body
  useBodyClass('checkoutBody');
  useHtmlClass('checkoutHtml');

  const [queryString] = useQueryString();
  const checkoutHeaders = getHeaders(queryString);
  const parterName = getParterName(queryString);

  if (!parterName) {
    return (
      <IframeWrapper>
        <OrderLayoutError
          message="Checkout error, you can try refresh your page."
          isCheckout
        />
      </IframeWrapper>
    );
  }

  // Setup token so that auth will be able to work without cookie
  useEffect(() => {
    ShApiClient.updateHeaders(headers => ({
      ...headers,
      ...checkoutHeaders,
    }));
  }, []);

  const currentClientUserAsync = useAsync(async () => {
    const currentUser = await getCurrentUserOption();
    if (currentUser && currentUser.type !== 'CLIENT_USER') {
      const error = new Error(
        'unexpected user type for checkout = ' + currentUser.type,
      );
      // TODO add error logging feature to useAsync lib
      console.error(error);
      throw error;
    }
    console.debug('order checkout with user', currentUser);
    return currentUser;
  }, []);

  const currentUser = currentClientUserAsync.result;

  const isValidAddress =
    queryString['address'] &&
    queryString['latitude'] &&
    queryString['longitude'];

  const baseInitialState = useInitialOrderPageState();

  const initialState: OrderPageState = {
    ...baseInitialState,
    country: queryString['country'] as ShCountry | 'FR', //'FR',
    address: isValidAddress
      ? {
          address: queryString['address']!,
          latitude: parseFloat(queryString['latitude']!),
          longitude: parseFloat(queryString['longitude']!),
        }
      : undefined,

    infos: {
      ...baseInitialState.infos,
      reference: queryString['ref'],
    },
  };

  if (currentClientUserAsync.loading) {
    return <OrderLayoutSpinner />;
  }
  if (currentClientUserAsync.error) {
    return (
      <IframeWrapper>
        <OrderLayoutError message="Checkout Error" isCheckout />
      </IframeWrapper>
    );
  }

  return (
    <IframeWrapper parterName={parterName}>
      <CheckoutCurrentUserProvider initialCurrentUser={currentUser}>
        <OrderPageMainLayout
          showHeader={false}
          showFooter={false}
          className="order-checkout"
          css={{ background: 'white' }}
        >
          <OrderPageContextProvider
            isCheckout={true}
            initialState={initialState}
          >
            <OrderPageContent />
          </OrderPageContextProvider>
        </OrderPageMainLayout>{' '}
      </CheckoutCurrentUserProvider>
    </IframeWrapper>
  );
};

export const onCloseIframe = () => {
  parent.postMessage('closeShoootinCheckout', '*');
};

const IframeWrapper = ({
  children,
  parterName,
}: {
  children: ReactNode;
  parterName?: string;
}) => {
  return (
    <div className="iframeWrapper">
      <div className="checkoutClose" onClick={() => onCloseIframe()}>
        <CloseSvg stroke="white" strokeWidth={2} />
      </div>
      {children}

      <div className="checkoutFooter">
        Powered by{' '}
        <a href="https://shoootin.com" target="_blank">
          {' '}
          <LogoSvg fill="white" height={10} width={60} />
        </a>{' '}
        {parterName && <>for {parterName}</>}
      </div>
    </div>
  );
};

export default OrderPageCheckout;
