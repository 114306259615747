import { useEffect } from 'react';

const addHtmlClass = (className: string) =>
  document.documentElement.classList.add(className);
const removeHtmlClass = (className: string) =>
  document.documentElement.classList.remove(className);

const useHtmlClass = (className: string) => {
  useEffect(() => {
    addHtmlClass(className);

    // Clean up
    return () => {
      removeHtmlClass(className);
    };
  }, [className]);
};

export default useHtmlClass;
