import { useState, useEffect } from 'react';
import { isBrowser } from '../utils/gatsbyUtils';
import { isNil } from 'lodash';

// We only maintain a boolean in state because
// - we want the boolean to change asap the threshold is reached
// - we want the scroll listener to be simple/performant (avoid rerenders)
const useScrollTopThresholdReached = (
  threshold?: number,
  element?: HTMLElement,
): boolean => {
  const [reached, setReached] = useState(false);

  useEffect(() => {
    const scrollElement = element || window;
    const getScrollTop = () =>
      element ? element.scrollTop : window.pageYOffset;

    const update = () => {
      const newReached =
        isBrowser() && !isNil(threshold) ? getScrollTop() >= threshold : false;

      if (newReached !== reached) {
        setReached(newReached);
      }
    };

    update();
    scrollElement.addEventListener('scroll', update);
    return () => scrollElement.removeEventListener('scroll', update);
  }, [threshold, element, reached]);

  return reached;
};

export default useScrollTopThresholdReached;
