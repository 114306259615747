import React from 'react';

const CloseSvg = (props: any) => (
  <svg
    className="close"
    viewBox="0 0 19 19"
    height={16}
    width={24}
    fill="none"
    {...props}
  >
    <line x1={2.5} y1={2.5} x2={16.5} y2={16.5} />
    <line x1={16.5} y1={2.5} x2={2.5} y2={16.5} />
  </svg>
);

export default CloseSvg;
