import React from 'react';
import OrderPageMainLayout from '../orderPageMainLayout';
import { ShSpinner } from '@shoootin/components-web';

const OrderLayoutSpinner = () => (
  <OrderPageMainLayout>
    <ShSpinner />
  </OrderPageMainLayout>
);
export default OrderLayoutSpinner;
